import { BACKEND_URL } from './Constants';
import './Head.css'

export function Head({loggedIn, setLoggedIn}: any) {

    const logout = async () => {
        const localSessionId = window.localStorage.getItem("sessionId")
        const response = await fetch(`${BACKEND_URL}/logout/${localSessionId}`, {
            method: "GET",
        })
        await response.text()
        setLoggedIn(false)
        window.localStorage.removeItem("sessionId")
    }

    const logoutButton = (
        <button className='logout-button' onClick={logout}>Logout</button>
    )
    return (
        <>
            <div className='header'>
                <h1>ToDo Listen</h1>
                {loggedIn && logoutButton}
            </div>
        </>
    )
}
