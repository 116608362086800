import { useEffect, useState } from "react"
import { ToDoItem } from "./ToDoItem"
import "./ToDoListViewer.css"
import "../App.css"
import { OnChangePayload } from "../OnChangePayload"
import { humanReadableDateToString } from "./Utils"
import { BACKEND_URL } from "../Constants"
import { UpdatePopup } from "./UpdatePopup"

async function fetchAllItems(setData: any, listId: number) {
    const response = await fetch(
        `${BACKEND_URL}/list/${listId}/items/unprocessed`,
        {
            headers: {
                 accept: 'application/json',
                 session_id: window.localStorage.getItem("sessionId")!
            }
        })
    const result: ToDoItem[] = await response.json()
    setData(result)
}

function invalidateItem(id: number, onChange: any) {
    const invalidate = async () => {
        const response = await fetch(
            `${BACKEND_URL}/items/${id}/invalidate`,
            {
                headers: {
                    session_id: window.localStorage.getItem("sessionId")!
                }
            }
            )
        await response.text()
        onChange()
    }
    invalidate()
}

export function ToDoListViewer({onChange, parentCount, currentListId}: OnChangePayload) {
    const [toDoItems, setToDoItems] = useState<ToDoItem[]>([])
    const [updateItemId, setUpdateItemId] = useState(0)

    useEffect(() => {
        const doFetch = async () => {
            await fetchAllItems(setToDoItems, currentListId)
        }
        doFetch()
    }, [parentCount, currentListId]);



    const showPopup = (str: string) => {
        document.getElementById("popup")!.style.display = 'block'
        document.getElementById("popup_content")!.innerHTML = str.replaceAll("\n", "<br />")
    }

    const hidePopup = () => {
        document.getElementById("popup")!.style.display = 'none'
    }

    const showEditPopup = (item: ToDoItem) => {
        document.getElementById("editPopup")!.style.display = 'block';
        (document.getElementById("editName") as HTMLInputElement).value = item.name;
        (document.getElementById("editDescription") as HTMLTextAreaElement).value = item.details ?? "";
        (document.getElementById("editPriority") as HTMLInputElement).value = item.priority.toString();
        setUpdateItemId(item.id)
    }

    const createPopupContent = (item: ToDoItem) => {
        const detailsExists: boolean = item.details !== null && item.details !== ""
        const detailsPart: string = detailsExists ? item.details + "<br /> <br />" : ""
        return detailsPart + "Task erstellt: " + humanReadableDateToString(item.createdAt)
    }

    const data = toDoItems.map(item => (
        <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.priority}</td>
            <td>
                <button className="symbol-button-blue" onClick={() => {showPopup(createPopupContent(item))}}>
                    i
                </button>
            </td>
            <td>
                <button className="symbol-button-orange" onClick={() => {showEditPopup(item)}}>
                    🖊️
                </button>
            </td>
            <td>
                <button className="symbol-button-green" onClick={() => {invalidateItem(item.id, onChange)}}>
                    ✓
                </button>
            </td>
        </tr>
    ))

    return (
        <div>
            <h3>ToDo-Liste:</h3>
            <div id="popup" onClick={hidePopup}>
                <p id="popup_content"></p>
            </div>
            <UpdatePopup updateItemId={updateItemId} onChange={onChange} />
            <table className="todo-table">
                <thead>
                    <tr>
                        <th>Task</th>
                        <th>Prio</th>
                        <th />
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data}
                </tbody>
            </table>
        </div>
    )
}
