import { useEffect } from "react"
import "../App.css"
import { BACKEND_URL } from "../Constants"

function deleteList(onChange: any, id: number, setCurrentListId: any) {
    const doDelete = async () => {
        const response = await fetch(`${BACKEND_URL}/list/${id}`, {
            method: "DELETE",
            headers: {
                session_id: window.localStorage.getItem("sessionId")!
            }
        })
        await response.text()
        setCurrentListId(1)
        onChange()
    }

    doDelete()
}

const showFinalDeleteButton = () => {
    const btnElement = document.getElementById("finalDeleteButton");
    if (btnElement) btnElement.style.display = "block"
}

const hideFinalDeleteButton = () => {
    const btnElement = document.getElementById("finalDeleteButton");
    if (btnElement) btnElement.style.display = "none"
}

export function ListDeleter({onChange, currentListId, setCurrentListId, parentCount}: any) {
    useEffect(() => {
        hideFinalDeleteButton()
    }, [parentCount])

    return (
        <div>
            <h3>Aktuelle Liste löschen</h3>
            <button onClick={showFinalDeleteButton}>
                löschen
            </button>
            <button className="hidden" id="finalDeleteButton" onClick={() => deleteList(onChange, currentListId, setCurrentListId)} >
                wirklich löschen
            </button>
        </div>
    )
}
