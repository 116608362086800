import { BACKEND_URL } from "../Constants";

export interface Item {
    name: string
    details: string
    priority: string
}

export const validateItem = (item: Item) => {
    if (item.name === "") {
        alert("Name muss ausgefüllt sein")
        return false
    }
    try {
        parseInt(item.priority)
    } catch (error) {
        alert("Prio muss eine ganze Zahl sein")
        return false
    }
    return true
}

export function NewItemGenerator({currentListId, onChange}: any) {

    const clearFields = () => {
        (document.getElementById("name") as HTMLInputElement).value = "";
        (document.getElementById("details") as HTMLInputElement).value = "";
        (document.getElementById("priority") as HTMLInputElement).value = "0";
    }

    const addNewItem = (e: any) => {
        const item: any = {
            name: e.target.name.value,
            details: e.target.details.value,
            priority: e.target.priority.value
        }

        const doAddItem = async (data: any) => {
            const response = await fetch(
                `${BACKEND_URL}/list/${currentListId}/item`,
                {
                    method: "PUT",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                        "session_id": window.localStorage.getItem("sessionId")!
                    }
                }
                )
                await response.text()
                clearFields()
                onChange()
            }

        if (validateItem(item)) {
            doAddItem(item)
        }
        e.preventDefault()
    }

    return (
        <div>
            <h3>Neues Item anlegen</h3>
            <form onSubmit={addNewItem}>
                <table className="newItemTable">
                    <tbody>
                    <tr>
                        <td>Name:</td>
                        <td>
                            <input
                                className="nearlyFullWidth"
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Name des Items" />
                        </td>
                    </tr>
                    <tr>
                        <td>Beschreibung:</td>
                        <td>
                            <textarea
                                className="nearlyFullWidth"
                                id="details"
                                name="details"
                                rows={3}
                                cols={20}
                                placeholder="Optionale Beschreibung" />
                        </td>
                    </tr>
                    <tr>
                        <td>Prio:</td>
                        <td>
                            <input
                                className="nearlyFullWidth"
                                type="number"
                                id="priority"
                                name="priority"
                                defaultValue="0"
                                placeholder="Priorität (ganze Zahl)" />
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p><input type="submit" value="Item anlegen" /></p>
            </form>
        </div>
    )
}
