import { useEffect, useState } from "react"
import { ToDoItem } from "./ToDoItem"
import { OnChangePayload } from "../OnChangePayload"
import { humanReadableDateToString } from "./Utils"
import { BACKEND_URL } from "../Constants"

function fetchAllItems(setData: any, listId: number) {
    const doFetch = async () => {
        const response = await fetch(
            `${BACKEND_URL}/list/${listId}/items/processed`,
            {
                headers: {
                    accept: 'application/json',
                    session_id: window.localStorage.getItem("sessionId")!
                }
            })
        const result: ToDoItem[] = await response.json()
        setData(result)
    }
    doFetch()
}

function validateItem(id: number, onChange: any) {
    const invalidate = async () => {
        const response = await fetch(`${BACKEND_URL}/items/${id}/validate`, {
            headers: {
                session_id: window.localStorage.getItem("sessionId")!
            }
        })
        await response.text()
        onChange()
    }
    invalidate()
}


export function DoneListViewer({onChange, parentCount, currentListId}: OnChangePayload) {

    const [data, setData] = useState<ToDoItem[]>([]);

    useEffect(() => {
        fetchAllItems(setData, currentListId)
    }, [parentCount, currentListId]);

    let toDoList = data.map(item => (
        <tr key={item.id}>
            <td>{item.name}</td>
            <td><button className="symbol-button-blue" onClick={() => {showPopup(createPopupContent(item))}}>i</button></td>
            <td><button className="symbol-button-orange" onClick={() => {validateItem(item.id, onChange)}}>↑</button></td>
        </tr>
    ))

    const showPopup = (str: string) => {
        document.getElementById("popup")!.style.display = 'block'
        document.getElementById("popup_content")!.innerHTML = str.replaceAll("\n", "<br />")
    }

    const hidePopup = () => {
        document.getElementById("popup")!.style.display = 'none'
    }

    const createPopupContent = (item: ToDoItem) => {
        const doneAt: string = item.doneAt ?? ""
        const detailsExist = item.details !== "" && item.details !== null
        const detailsPart = detailsExist ? item.details + "<br /> <br />" : ""
        return detailsPart + "Task erstellt: " + humanReadableDateToString(item.createdAt) + " <br />Task abgearbeitet: " + humanReadableDateToString(doneAt)
    }

    return (
        <div>
            <h3>Bereits abgearbeitet</h3>
            <div id="popup" onClick={hidePopup}>
                <p id="popup_content"></p>
            </div>
            <table className="todo-table">
                <thead>
                    <tr>
                        <th>Task</th>
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {toDoList}
                </tbody>
            </table>
        </div>
    )
}
