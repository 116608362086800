import { VERSION } from "./Constants";
import "./VersionFooter.css"

export function VersionFooter() {
    return (
        <p className="version">
            Version: {VERSION}
        </p>
    )
}
