import { BACKEND_URL } from "./Constants"
import "./Login.css"

interface LoginResponseDto {
    user: boolean
    loggedIn: boolean
    sessionId: string
}

export function Login({setLoggedIn}: any) {
    const login = async (user: string, pass: string) => {
        const request = {
            username: user,
            password: pass
        }
        const response = await fetch(`${BACKEND_URL}/login`, {
            method: "POST",
            body: JSON.stringify(request),
            headers: { "Content-Type": 'application/json', "Accept": "application/json" }
          })
          const responseJson: LoginResponseDto = await response.json()
          setLoggedIn(responseJson.loggedIn)
          window.localStorage.setItem("sessionId", responseJson.sessionId)
    }

    const tryLogin = (event: any) => {
        const username: string = event.target.username.value
        const password: string = event.target.password.value
        if (username.length === 0) {
            alert("Nutzername muss gesetzt sein.")
            return
        }
        if (password.length === 0) {
            alert("Passwort muss gesetzt sein.")
            return
        }
        login(username, password)
        event.preventDefault()
    }

    return (
        <form className="login" onSubmit={tryLogin}>
            <table className="login-table">
                <tr>
                    <td>Login:</td>
                    <td><input className="login-input" type="text" name="username" placeholder="Nutzername" /></td>
                </tr>
                <tr>
                    <td>Passwort:</td>
                    <td><input className="login-input" type="password" name="password" placeholder="Passwort" /></td>
                </tr>
            </table>
            <p><input className="login-button" type="submit" value={"login"} /></p>
        </form>
    )
}
