import { useEffect, useState } from "react"
import { BACKEND_URL } from "../Constants"
import { ListDeleter } from "./ListDeleter"
import { ListLocker } from "./ListLocker"

function setListLocked(id: number, setLocked: any) {
    const isLocked = async () => {
        const response = fetch(
            `${BACKEND_URL}/list/${id}/lock/status`,
            {
                headers: {
                    session_id: window.localStorage.getItem("sessionId")!
                }
            })
            .then(r => r.text())
            .then(t => setLocked(t === "true"))
        await response
    }

    isLocked()
}

function unlockList(id: number, setLocked: any) {
    const doUnlock = async () => {
        fetch(
            `${BACKEND_URL}/list/${id}/unlock`,
            {
                headers: {
                    session_id: window.localStorage.getItem("sessionId")!
                }
            }
            )
            .then(r => r.status === 200 && setLocked(false))
    }

    doUnlock()
}

export function ListLockCoordinator({onChange, parentCount, currentListId, setCurrentListId} : any) {
    const [locked, setLocked] = useState(false)

    useEffect(() => {
            setListLocked(currentListId, setLocked)
        }, [currentListId]
    )

    const showUnlockButton = () => {
        document.getElementById("unlockButton")!.style.display = "block"
        // should disappear after a second
        setTimeout(() => {
            const elem = document.getElementById("unlockButton")
            if (elem != null) elem.style.display = "none"
        }, 1000)
    }

    const lockDeleteElement = (
        <>
            <ListLocker currentListId={currentListId} setLocked={setLocked} />
            <ListDeleter onChange={onChange} parentCount={parentCount} currentListId={currentListId} setCurrentListId={setCurrentListId} />
        </>
    )

    const lockedElement = (
        <div>
            <p className="list-locked-msg">
                Die aktuelle Liste ist geschützt und kann <span onClick={showUnlockButton}>nicht</span> gelöscht werden.
            </p>
            <button id="unlockButton" className="hidden" onClick={() => unlockList(currentListId, setLocked)}>entsperren</button>
        </div>
    )

    return (
        <div>
            {!locked && lockDeleteElement}
            {locked && lockedElement}
        </div>
    )
}
