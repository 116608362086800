import { BACKEND_URL } from "../Constants"
import { validateItem } from "./NewItemGenerator"

export function UpdatePopup({onChange, updateItemId}: any) {

    const hidePopup = () => {
        document.getElementById("editPopup")!.style.display = 'none'
    }

    const deleteItem = (id: number, onChange: any) => {
        const doDelete = async () => {
            const response = await fetch(`${BACKEND_URL}/items/${id}`, {
                method: "DELETE",
                headers: {
                    session_id: window.localStorage.getItem("sessionId")!
                }
            })
            await response.text()
            onChange()
        }
        doDelete()
        hidePopup()
    }

    const editItem = (e: any) => {
        const item: any = {
            name: e.target.editName.value,
            details: e.target.editDescription.value,
            priority: e.target.editPriority.value
        }

        const doEdit = async (data: any, id: number) => {
            const response = await fetch(
                `${BACKEND_URL}/item/${id}`,
                {
                    method: "PATCH",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                        "session_id": window.localStorage.getItem("sessionId")!
                    }
                }
            )
            await response.text()
            onChange()
        }

        if (validateItem(item)) {
            doEdit(item, updateItemId)
            hidePopup()
        }
        e.preventDefault()
    }

    return (
        <div id="editPopup">
            <form onSubmit={editItem}>
                <table className="updateTable" align="center" width={"100%"}>
                    <tr>
                        <td>
                            Name:
                        </td>
                        <td>
                            <input
                                type="text"
                                id="editName"
                                name="editName"
                                placeholder="Name"
                                className="nearlyFullWidth" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Beschreibung:
                        </td>
                        <td>
                            <textarea
                                id="editDescription"
                                name="editDescription"
                                placeholder="Beschreibung"
                                className="nearlyFullWidth" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Prio:
                        </td>
                        <td>
                            <input
                                type="number"
                                id="editPriority"
                                name="editPriority"
                                className="nearlyFullWidth" />
                        </td>
                    </tr>
                </table>
                <br />
                <input className="button-orange" type="submit" value="Task ändern" />
            </form>
            <br />
            <button id="deleteButton" className="button-red" onClick={() => {
                    deleteItem(updateItemId, onChange);
                }}>
                    Task löschen
            </button>
            <br /><br />
            <button onClick={hidePopup}>
                Fenster schließen
            </button>
        </div>
    )
}
