import { useEffect, useState } from "react"
import { ToDoList } from "./ToDoList"
import { BACKEND_URL } from "../Constants"

export function ListSelector({setCurrentListId, currentListId}: any) {
    const [lists, setLists] = useState<ToDoList[]>([])

    useEffect(() => {
        const fetchLists = async (setLists: any) => {
            const response = await fetch(
                `${BACKEND_URL}/lists`,
                {
                    headers: {
                        session_id: window.localStorage.getItem("sessionId")!
                    }
                })
            const result: ToDoList[] = await response.json()
            setLists(result)
        }
        fetchLists(setLists)
    }, [currentListId])

    const data = lists.map(item => (
        <option key={item.id} accessKey={item.id.toString()}>{item.name}</option>
    ))

    const update = () => {
        const selectElem: any = document.getElementById("select")
        if (selectElem) {
            const selectedOption = selectElem.options[selectElem.options.selectedIndex]
            setCurrentListId(selectedOption?.accessKey)
        }
    }

    const getDefaultValue = () => lists.filter((l) => l.id === currentListId).at(0)?.name

    return (
        <div>
            <h3>Liste auswählen</h3>
            <select id="select" defaultValue={getDefaultValue()}>
                {data}
            </select>
            <button onClick={update}>Liste auswählen</button>
        </div>
    )
}
