import { BACKEND_URL } from "../Constants"

export function NewListGenerator({onChange, setCurrentListId}: any) {

    const addNewList = (e: any) => {
        const item: any = {
            name: e.target.name.value,
        }

        const doAddList = async (data: any) => {
            const response = await fetch(
                `${BACKEND_URL}/list`,
                {
                    method: "PUT",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                        "session_id": window.localStorage.getItem("sessionId")!
                    }
                }
            )
            const newId: string = await response.text()
            setCurrentListId(parseInt(newId))
            onChange()
        }

        doAddList(item)
        const element = document.getElementById("name")
        if (element) (element as HTMLInputElement).value = ""
        e.preventDefault()
    }

    return (
        <div>
            <h3>Neue Liste anlegen</h3>
            <form onSubmit={addNewList}>
                Name: <input type="text" id="name" name="name" />
                <p><input type="submit" value="Liste anlegen" /></p>
            </form>
        </div>
    )
}
