import "../App.css"
import { BACKEND_URL } from "../Constants"

function lockList(id: number, setLocked: any) {
    const doLock = async () => {
        fetch(`${BACKEND_URL}/list/${id}/lock`,
        {
            headers: {
                session_id: window.localStorage.getItem("sessionId")!
            }
        })
            .then(r => r.status === 200 && setLocked(true))
    }

    doLock()
}

export function ListLocker({currentListId, setLocked}: any) {

    return (
        <div>
            <h3>Aktuelle Liste gegen Löschung schützen</h3>
            <button onClick={() => lockList(currentListId, setLocked)}>
                schützen
            </button>
        </div>
    )
}
