import { useEffect, useState } from 'react';
import './App.css';
import { Head } from './Head';
import { DoneListViewer } from './list/DoneListViewer';
import { ToDoListViewer } from './list/ToDoListViewer';
import { ListSelector } from './list/ListSelector';
import { NewItemGenerator } from './list/NewItemGenerator';
import { NewListGenerator } from './list/NewListGenerator';
import { ListLockCoordinator } from './list/ListLockCoordinator';
import { VersionFooter } from './VersionFooter';
import { Login } from './Login';
import { BACKEND_URL } from './Constants';

interface ValidityDto {
  sessionId: string,
  username: string,
  sessionValid: boolean
}

const doCheckForValidity = async (localSessionId: string) => {
  const response = await fetch(`${BACKEND_URL}/auth/session/validity`, {
    method: "POST",
    body: JSON.stringify({
      sessionId: localSessionId
    }),
    headers: {
      "Accept": 'application/json',
      "Content-Type": "application/json",
      "session_id": window.localStorage.getItem("sessionId")!
    }
  })
  const responseJson: ValidityDto = await response.json()
  return responseJson;
}

function App() {
  const [count, setCount] = useState(0)
  const [currentListId, setCurrentListId] = useState<number>(1)
  const [loggedIn, setLoggedIn] = useState(false)

  const onChange = () => {
    setCount(count + 1)
  }

  useEffect(() => {
    const checkForValidity = async () => {
      const localSessionId = window.localStorage.getItem("sessionId")

      if (localSessionId === null) {
        setLoggedIn(false)
        return
      }

      const response = await doCheckForValidity(localSessionId!)
      console.log(response)
      setLoggedIn(response.sessionValid)
    }
    checkForValidity()
  }, [count])

  const loginSite = (
    <Login setLoggedIn={setLoggedIn} />
  )

  const mainSite = (
    <>
      <ListSelector setCurrentListId={setCurrentListId} currentListId={currentListId} />
      <NewItemGenerator currentListId={currentListId} onChange={onChange} />
      <ToDoListViewer onChange={onChange} parentCount={count} currentListId={currentListId} />
      <DoneListViewer onChange={onChange} parentCount={count} currentListId={currentListId} />
      <NewListGenerator onChange={onChange} setCurrentListId={setCurrentListId} />
      <ListLockCoordinator onChange={onChange} parentCount={count} currentListId={currentListId} setCurrentListId={setCurrentListId} />
      <VersionFooter />
    </>
  )

  return (
    <>
      <Head loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
      {loggedIn ? mainSite : loginSite}
    </>
  );
}

export default App;
